<template>
  <router-view
    :date-range="dateRange"
    :loading="loading"
    @loading="$emit('loading', $event)"
  />
</template>

<script>
export default {
  name: 'BaseCompany',
  beforeRouteLeave (to, from, next) {
    this.$store.commit('clear', 'selectedCompany')
    next()
  },
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    company () {
      return this.$store.getters['rooof/getCompanyById'](this.$route.params.cid)
    }
  },
  watch: {
    company: {
      immediate: true,
      handler () {
        this.setSelectedCompany()
      }
    }
  },
  methods: {
    /**
     * Sets the selected company in the store.
     */
    setSelectedCompany () {
      if (this.company) {
        const selectedCompany = {
          human_name: this.company.human_name,
          id: this.company.id
        }
        this.$store.commit('set', ['selectedCompany', selectedCompany])
      }
    }
  }
}
</script>
